import {
  IPurchaseState,
  PurchaseStateAndDelivery,
  isUpcomingDelivery,
} from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { cloneDeep, compact, isNil } from "lodash";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import {
  DiscountApplicationType,
  DiscountValueType,
  IPurchaseStateLineItemDiscount,
} from "../../interfaces/Discount";
import { SellingPlanPricingPolicyAdjustmentType } from "../../shopifyGraphQL/api";

type ValidatedLineItem = Omit<IPurchaseStateLineItem, "currentSequenceNumber" | "sequential"> & {
  currentSequenceNumber: NonNullable<IPurchaseStateLineItem["currentSequenceNumber"]>;
  sequential: NonNullable<IPurchaseStateLineItem["sequential"]>;
};

/**
 * Information that needs to be passed to future deliveries.
 * Eg: number of skipped deliveries, continuous discounts etc
 */
interface SequenceData {
  skipsSoFar: number;
}

export const generateFutureCode = (line: ValidatedLineItem) => `SEQUENTIAL_FUTURE_DISCOUNT_CODE-${line.id}`;

const validatedSequentialLineItem = (
  line: IPurchaseStateLineItem,
  delivery: PurchaseStateAndDelivery
): ValidatedLineItem | null => {
  if (
    isNil(line.currentSequenceNumber) ||
    isNil(line.sequential) ||
    delivery.indexFromNext < 0 ||
    line.sellingPlanId !== delivery.purchaseState.sellingPlanId
  ) {
    return null;
  }
  return {
    ...line,
    currentSequenceNumber: line.currentSequenceNumber,
    sequential: line.sequential,
  };
};

const handleRestartLoopEndAction = (
  subscription: IPurchaseState,
  sequential: SequentialApi.Sequential.Type,
  endAction: SequentialApi.EndActions.Restart.Type,
  sequenceNumberForThisLineItem: number,
  line: ValidatedLineItem
): number => {
  const restartFromSequenceNumber = endAction.restartFromSequenceNumber;
  const lengthOfInitialRun = endAction.orderNumber;
  const lengthOfSubsequentRuns = Math.max(1, endAction.orderNumber - restartFromSequenceNumber + 1);
  const numberOfOrdersBeyondInitialRun = sequenceNumberForThisLineItem - lengthOfInitialRun;
  const numberOfOrdersIntoCurrentRun = numberOfOrdersBeyondInitialRun % lengthOfSubsequentRuns;
  const numberOfOrdersSkippedByRestart = restartFromSequenceNumber - 1;

  sequenceNumberForThisLineItem = numberOfOrdersIntoCurrentRun + numberOfOrdersSkippedByRestart;

  const isRestartingOnCurrentRun = numberOfOrdersIntoCurrentRun === 0;

  if (isRestartingOnCurrentRun) {
    const latestSwapBeforeSequenceRestarts: SequentialApi.Actions.Swap.Type | undefined =
      sequential.sequence.findLast((action): action is SequentialApi.Actions.Swap.Type => {
        return action.actionType === "SWAP" && action.orderNumber < restartFromSequenceNumber;
      });
    if (latestSwapBeforeSequenceRestarts) {
      swapVariantInLine(subscription, line, latestSwapBeforeSequenceRestarts.swapVariant);
    } else {
      swapVariantInLine(subscription, line, sequential.initialVariant);
    }
  }

  return sequenceNumberForThisLineItem;
};

const removePreviouslyGeneratedRecurringDiscounts = (subscription: IPurchaseState, line: ValidatedLineItem) => {
  subscription.discounts = subscription.discounts.filter(
    discount => discount.vendorId !== generateFutureCode(line)
  );
  line.discounts = line.discounts.filter(discount => discount.vendorId !== generateFutureCode(line));
};
const addGeneratedDiscount = (
  subscription: IPurchaseState,
  line: ValidatedLineItem,
  discount: SequentialApi.Actions.Discount.Type
) => {
  removePreviouslyGeneratedRecurringDiscounts(subscription, line);
  const newCode: IPurchaseStateLineItemDiscount = {
    type: DiscountApplicationType.DISCOUNT_CODE,
    code: "",
    usageCount: 0,
    value: discount.discount.type === "FIXED" ? discount.discount.amount * 100 : discount.discount.amount,
    valueType: discount.discount.type === "FIXED" ? DiscountValueType.FIXED : DiscountValueType.PERCENTAGE,
    orderWideDiscount: false,
    vendorId: generateFutureCode(line),
  };
  if (discount.discount.type === "FIXED") {
    newCode.currency = subscription.currency;
  }
  line.discounts.push(newCode);
  line.needsPricingUpdate = true;
  subscription.discounts.push(newCode);
};

const swapVariantInLine = (
  subscription: IPurchaseState,
  line: ValidatedLineItem,
  variant: SequentialApi.SequentialVariantWithParent.Type
) => {
  line.vnt = variant;
  if (
    line.pricingPolicy &&
    line.pricingPolicy.cycleDiscounts[0].adjustmentType !== SellingPlanPricingPolicyAdjustmentType.Price
  ) {
    line.pricingPolicy = null;
    line.needsPricingUpdate = true;
  }
  removePreviouslyGeneratedRecurringDiscounts(subscription, line);
};

const addSequentialToLineItem = (
  lineInput: IPurchaseStateLineItem,
  delivery: PurchaseStateAndDelivery,
  sequenceData: SequenceData
): IPurchaseStateLineItem | null => {
  const line = validatedSequentialLineItem(lineInput, delivery);
  if (isNil(line)) {
    return lineInput;
  }
  const sequential = line.sequential;

  const possibleRecurringDiscount =
    sequential.sequence.find(
      (seq): seq is SequentialApi.Actions.Discount.Type =>
        seq.actionType === "DISCOUNT" && seq.orderType === "ALL"
    ) ?? null;

  const sequenceNumberForThisLineItem: number =
    line.currentSequenceNumber + delivery.indexFromNext - sequenceData.skipsSoFar;
  let loopedSequenceNumberForThisLine = sequenceNumberForThisLineItem;

  const calcShouldRecurringDiscountBeApplied = (updatedLine: ValidatedLineItem) => {
    const isRecurringDiscountPresent = !!possibleRecurringDiscount;
    const isCurrentLineItemAfterRecurringDiscount =
      isRecurringDiscountPresent && sequenceNumberForThisLineItem > possibleRecurringDiscount.orderNumber;
    const wasFirstUpcomingOrderBeforeRecurringDiscount =
      isRecurringDiscountPresent && line.currentSequenceNumber < possibleRecurringDiscount.orderNumber;
    const hasSwapHappened = !!updatedLine.needsPricingUpdate;
    const shouldRecurringDiscountBeApplied =
      isRecurringDiscountPresent &&
      isCurrentLineItemAfterRecurringDiscount &&
      (wasFirstUpcomingOrderBeforeRecurringDiscount || hasSwapHappened);
    return shouldRecurringDiscountBeApplied;
  };

  removePreviouslyGeneratedRecurringDiscounts(delivery.purchaseState, line);

  if (possibleRecurringDiscount && calcShouldRecurringDiscountBeApplied(line)) {
    addGeneratedDiscount(delivery.purchaseState, line, possibleRecurringDiscount);
  }

  // END ACTIONS
  if (sequential.endAction.orderNumber <= sequenceNumberForThisLineItem) {
    switch (sequential.endAction.actionType) {
      case "END": {
        return null;
      }
      case "REPEAT": {
        return line;
      }
      case "RESTART": {
        loopedSequenceNumberForThisLine = handleRestartLoopEndAction(
          delivery.purchaseState,
          sequential,
          sequential.endAction,
          sequenceNumberForThisLineItem,
          line
        );
        if (possibleRecurringDiscount && calcShouldRecurringDiscountBeApplied(line)) {
          addGeneratedDiscount(delivery.purchaseState, line, possibleRecurringDiscount);
        }
        break;
      }
      default: {
        throw new Error("Unkown end action type");
      }
    }
  }

  // SEQUENCE ACTIONS
  const actions = sequential.sequence.filter(action => action.orderNumber === loopedSequenceNumberForThisLine);
  for (const action of actions) {
    switch (action.actionType) {
      case "SWAP": {
        if (action.swapVariant) {
          /**
           * Remove all previously generated discounts
           * Swap line item
           * Add recurring discount back in, if present
           */

          /**
            Since the current order has already had his swap process and commited to the contract,
            the current line already represents the current swap
            so we don't need to swap it here
          */
          if (!isUpcomingDelivery(delivery.indexFromNext)) {
            swapVariantInLine(delivery.purchaseState, line, action.swapVariant);
          }
          if (possibleRecurringDiscount && calcShouldRecurringDiscountBeApplied(line)) {
            addGeneratedDiscount(delivery.purchaseState, line, possibleRecurringDiscount);
          }
        }
        break;
      }
      case "DISCOUNT": {
        addGeneratedDiscount(delivery.purchaseState, line, action);
        break;
      }
      default: {
        throw new Error("Unknown action type");
      }
    }
  }

  return line;
};

const addSequentialToDeliveries = (deliveries: PurchaseStateAndDelivery[]) => {
  const sequenceData: SequenceData = { skipsSoFar: 0 };

  return deliveries.reduce((acc, currentDelivery, index) => {
    const newDelivery: PurchaseStateAndDelivery = cloneDeep(currentDelivery);

    // If not the first delivery, copy the purchase state from the previous delivery
    if (index > 0) {
      const previousDelivery = acc[index - 1];
      newDelivery.purchaseState = cloneDeep(previousDelivery.purchaseState);
    }

    if (newDelivery.isSkipped) {
      sequenceData.skipsSoFar += 1;
      acc.push(newDelivery);
      return acc;
    }

    newDelivery.purchaseState.stLineItems = compact(
      newDelivery.purchaseState.stLineItems.map(lineItem =>
        addSequentialToLineItem(lineItem, newDelivery, sequenceData)
      )
    );

    acc.push(newDelivery);
    return acc;
  }, [] as PurchaseStateAndDelivery[]);
};

export const sequentialWalker = (deliveries: PurchaseStateAndDelivery[]): PurchaseStateAndDelivery[] => {
  if (!deliveries.length) {
    return deliveries;
  }

  if (!deliveries[0].purchaseState.stLineItems.some(li => !!li.sequential)) {
    return deliveries;
  }

  const newDeliveries = addSequentialToDeliveries(deliveries);

  return newDeliveries.filter(delivery =>
    delivery.purchaseState.stLineItems.some(li => li.sellingPlanId === delivery.purchaseState.sellingPlanId)
  );
};
